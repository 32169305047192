import React from 'react'
import breakpoints from '../styles/tokens/exports/breakpoints.module.scss';

// DOCS: Not using gatsby dynanmic image compression and hand-off asset compression to the user. Suggest Sharp CLI in docs.

function Image({ src, srcSet, alt, title, lazy, className, ...other }) {
  if (!srcSet) {
    return (
      <img
        src={src} 
        className={className}
        alt={alt} 
        title={title} 
        loading={lazy === true ? 'lazy' : null} 
      />
    );
  }
  else {
    return (
      <picture>
        {srcSet.map((sourceObj, i) => (
          <source 
            srcSet={sourceObj.src} 
            media={getMediaString(sourceObj)}
            // replace any '/' with '-' (avoids React unique "key" prop warning)
            key={`${i}-${sourceObj.src.replace(/\//g, "-")}`}
          />
        ))}
        <img
          src={src} 
          className={className}
          alt={alt} 
          title={title} 
          loading={lazy === true ? 'lazy' : null} />
      </picture>
    );
  }
}

function getMediaString(source) {
  if (source.media) {
    return source.media;
  }
  
  let prefix = !source.direction ? 'max' : source.direction;
  
  let width;
  if (typeof source.breakpoint === 'string') {
    try {
      let breakpoint = breakpoints[source.breakpoint];
      if (!breakpoint) throw new Error(
        `Image: Invalid breakpoint passed in source object: ` + 
        `{ breakpoint: ${source.breakpoint}, src: ${source.src} }`
      );
    } catch (err) {
      console.error(err);
    }
    
    // console.trace();
    width = breakpoints[source.breakpoint];
  } else {
    width = source.breakpoint + 'px';
  }

  return `(${prefix}-width: ${width}`;

  
}

export default Image;