import React from 'react';
import Text from '@components/Text';
import Image from '@components/Image';
import GoogleForm from '@components/GoogleForm';
import { motion } from 'framer-motion';

import avatar from '@static/images/avatar.jpeg';

export default function PageContent(props){



  let page = props.page

  
  if ( page === 0) {

    return(
      <motion.div initial={{ opacity: 0 }} animate={{opacity: 1}} transition={{ease:"easeIn", duration: .75}}  className="services-content">
        <Text as="h3" className="header">Professional Audio Services</Text>
        <ul className="services-list">
          <li>Mixing + mastering on professional grade software and hardware</li>
          <li>Live recording sessions in a professional studio</li>
          <li>Live Sound Mixing + Equiptment Operations</li>
          <li>Voice-over work</li>
          <li>General audio editing</li>
        </ul>
      </motion.div>
    )
  } else if ( page === 1) {

      return(
      <motion.div initial={{ opacity: 0 }} animate={{opacity: 1}} transition={{ease:"easeIn", duration: .75}} className="about-content">
        <Text as="h3" className="header">About Brian</Text>
        <div className="about-grid">
          <div className="about-img-and-links">
            <div className="about-img-container">
              <Image src={avatar} lazyload/>
            </div>
            <div className="about-links-list-mobile">
              <a href="https://www.instagram.com/glufmister/" target="_blank" rel="noreferrer">Insta</a>
              <a href="https://open.spotify.com/playlist/2VK9TWToC2Xxz0T7AHmz3F" target="_blank" rel="noreferrer">Spotify</a>
            </div>
          </div>
          <div className="about-link-list-and-text">
            <div className="about-links-list-desktop">
              <a href="https://www.instagram.com/glufmister/" target="_blank" rel="noreferrer">Insta</a>
              <a href="https://open.spotify.com/playlist/2VK9TWToC2Xxz0T7AHmz3F" target="_blank" rel="noreferrer">Spotify</a>
            </div>
            <Text as="p">Brian has a bachelors in electronic recording arts and 5+ years of experience working in professional recording studios in Los Angeles, California, Nashville, Tennessee and is currently in Charlotte, North Carolina.</Text>
            <div className="Footer-Mobile">
              <Text as="p">Brian Gluf Audio 2021 &#169; </Text>
              <Text as="p">Website by <a href="https://www.mgluf.design/" target="_blank" rel="noreferrer">Matt Gluf</a></Text>
            </div>
          </div>
          
        </div>
      </motion.div>
      )
  } else if (page === 2) {


    let spotifyFrame = document.querySelector("#gatsby-focus-wrapper > div > div > div.content-container > div.Spotify > iFrame")
    console.log(spotifyFrame);

    return(
      <div className="Spotify">
        <iframe title="B.Gluf Portolio" src="https://open.spotify.com/embed/playlist/2VK9TWToC2Xxz0T7AHmz3F" width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </div>
    )
   
  }

  else if (page === 3) {

    return(
      <div>
        <GoogleForm/>
      </div>
    )
   
  }

}

