import React from 'react'
import Link from './Link';
import { classdFn as classNames } from 'classd';

//TODO: add Nav.Container as content wrapper component
//TODO: make it possible to set activeClassName for entire Nav component rather than each Item
//TODO update JSDocs to reflect changes to Nav
/**
 * Renders NavItems, NavRegions, or any other 
 * child elements at the root of Nav
 * 
 * @param props
 * @param props.className - custom class to pass to Nav container
 */
function Nav({ cols, className, children}) {
  // For conditionally rendering NavItem children properly
  const hasChildType = getChildrenTypes(children);

  let layoutClass;
  if (cols) layoutClass = `grid-template-cols-${cols}`;

  const classes = classNames(
    'Nav', 
    className,
    layoutClass,
  );

  if (hasChildType.NavItem) {
    // Wrap child NavItems in a ul
    return (
      <nav className={classes}>
        <ul className="Nav__ItemList Nav__ItemList--horizontal">
          {children}
        </ul>
      </nav>
    );
  } else {
    return (
      <nav className={classes}>
        {children}
      </nav>
    );
  }
}

/**
 * Renders NavItems or any other child elements in a container within Nav
 * 
 * @param props 
 * 
 */
function NavSection({ span, className, children }) {
  // For conditionally rendering NavItem children properly
  const hasChildType = getChildrenTypes(children);

  let layoutClass;
  if (span) layoutClass = `grid-col-${span}`;

  const classes = classNames(
    'Nav__Section', 
    className,
    layoutClass,
  );

  if (hasChildType.NavItem) {
    // Wrap child NavItems in a ul
    return (
      <div className={classes}>
        <ul className="Nav__ItemList">
          {children}
        </ul>
      </div>
    );
  } else {
    return (
      <div className={classes}>
        {children}
      </div>
    );
  }
}

function NavItemList() {
  return null;
}

function NavDropdown({ label, to, icon, children }) {
  // icon:
  //   default: chevron down
  //   false: no icon
  //   truthy: replace with prop value
  
  return (
    <div 
      className="Nav__Item Nav__Item--dropdown" 
      >
        {to 
          ? <Link 
              to={to} 
              className="Nav__Link" 
              activeClassName="Nav__Link--active" 
              disableDefaultClass
              >
                {label}
            </Link>
          : <div className="Nav__Link">{label}</div>
        }
        <div className="Nav__DropdownWrapper">
          <div className="Nav__Dropdown">
            <ul className="Nav__ItemList Nav__ItemList--vertical">
              {children}
            </ul>
          </div>
        </div>
        
    </div>
  );
}

function NavItem({ label, to, external, dropdown, className, id, onClick, children }) {
  const classes = classNames(
    'Nav__Item',
    className,
  );

  if (to) {
    return (
      <li className={classes}>
        {dropdown 
        ? 
          <NavDropdown to={to} label={label}>
            {children}
          </NavDropdown>  
        : 
          <Link 
            to={to} 
            className="Nav__Link" 
            activeClassName="Nav__Link--active"
            id={id}
            onClick={onClick}
            external={external}
            disableDefaultClass
            disableTip
            >
              {label ? label : children}
          </Link>
        }
      </li>
    );
  } else if (dropdown) {
    return (
      <NavDropdown label={label}>
        {children}
      </NavDropdown>
    );
  } else {
    return (
      <li className={classes}>
        {label ? label : children}
      </li>
    );
  }
}

function getChildrenTypes(children) {
  let types = {
    NavItem: false,
    NavItemList: false,
    NavSection: false,
  };

  for (const child of React.Children.toArray(children)) {
    switch (child.type) {
      case NavItem:
        types.NavItem = true;
        break;
      case NavItemList:
        types.NavItemList = true;
        break;
      case NavSection:
        types.NavSection = true;
        break;
      default: 
        break;
    }
  }

  return types;
}

Nav.propTypes = {
  children: (props, propName) => {
    const prop = props[propName];
    // Check for invalid Nav child composition
    // NavItem and NavSection should not be siblings
    let error = null;
    let nav = {
      hasChildNavItem: false,
      hasChildNavSection: false,
    }
    React.Children.forEach(prop, (child) => {
      // Check for Nav.Item child as sibling of Nav.Section
      if (child.type === NavItem && !nav.hasChildNavItem) {
        nav.hasChildNavItem = true;
      } else if (child.type === NavSection && !nav.hasChildNavSection) {
        nav.hasChildNavSection = true;
      }
      if (nav.hasChildNavItem && nav.hasChildNavSection) {
        error = new Error('Nav: children: Nav.Item cannot be a sibling of Nav.Section. Nest Nav.Item components within a Nav.Section instead.');
      }
      
      // Check for Nav.Item as sibling of non Nav.Item
      if (nav.hasChildNavItem && child.type !== NavItem) {
        error = new Error(`Nav: children: Nav.Item must only be siblings of other Nav.Item, but a sibling of type '${child.type}' was found at the root of a Nav instance.`);
      }
      
      // Check for Nav.Item as sibling of non Nav.Item within child's children 
      // (Only if child type is NavItem)
      if (child.props.children && child.type === NavItem) {
        let hasNavItemSibling = false;
        for (const c of child.props.children) {
          if (!hasNavItemSibling && c.type === NavItem) {
            hasNavItemSibling = true;
          } else if (hasNavItemSibling && c.type !== NavItem) {
            error = new Error(
              `Nav: children: Nav.Item must only be siblings of other Nav.Item, but a sibling of type '${c.type}' ` +
              `was found in parent with label '${child.props.label}'`
            );
          }
        }
      }
    });
    return error;
  },
}




Nav.Item = NavItem;
Nav.List = NavItemList;
Nav.Section = NavSection;
export default Nav;