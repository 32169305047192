import React from 'react';
import Text from '@components/Text';



export default function GoogleForm(props){


  return (
    <>
      <div className="contact-content">
        <form action="https://getform.io/f/a1ceb13c-97ab-4293-be37-f396a0e93d81" method="POST">
            <Text as="h5">Name</Text>
            <input type="text" name="name" required></input>
            <Text as="h5">Email</Text>
            <input type="email" name="email" required></input>
            <Text as="h5">Message</Text>
            <textarea type="text" name="message" required></textarea>
            <button type="submit">Send</button>
        </form>
      </div>
    </>
  );

}