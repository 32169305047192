import React, { useEffect, useState } from 'react';
import Nav from '@components/Nav';
import PageContent from '@components/PageContent';
import Svg from '@components/Svg';

// import vuON from '@static/images/web_VU-on-export.svg';
// import vuOFF from '@static/images/VU-off-export.png';


export default function Home() {

  const [page, setPage] = useState(0);

  let bars
  
  function checkBars() {
    if (bars.className === "bars-play") {
      console.log("YES") 
    } else {
      console.log("NOPE")
    }
  }

  useEffect(() => {

    bars =  document.getElementById("bars")
    checkBars();


    let url = typeof window !== 'undefined' ? window.location.href : '';
    console.log(url)

    
    if (page === 0) {

      document.getElementById("bars").classList.remove("bars-play")

      console.log("Page -- Services")
      document.getElementById("services").classList.add("Nav__Link--active")
      document.getElementById("about").classList.remove("Nav__Link--active")
      document.getElementById("portfolio").classList.remove("Nav__Link--active")
      document.getElementById("contact").classList.remove("Nav__Link--active")
    }

    if ( page === 1 ) {

      document.getElementById("bars").classList.remove("bars-play")

      console.log("Page -- About")
      document.getElementById("about").classList.add("Nav__Link--active")
      document.getElementById("services").classList.remove("Nav__Link--active")
      document.getElementById("portfolio").classList.remove("Nav__Link--active")
      document.getElementById("contact").classList.remove("Nav__Link--active")
    }

    if ( page === 2 ) {

      document.getElementById("bars").classList.add("bars-play")

      console.log("Page -- Portfolio")
      document.getElementById("portfolio").classList.add("Nav__Link--active")
      document.getElementById("services").classList.remove("Nav__Link--active")
      document.getElementById("about").classList.remove("Nav__Link--active")
      document.getElementById("contact").classList.remove("Nav__Link--active")
    }

    if ( page === 3 ) {

      document.getElementById("bars").classList.remove("bars-play")

      console.log("Page -- Contact")
      document.getElementById("portfolio").classList.remove("Nav__Link--active")
      document.getElementById("services").classList.remove("Nav__Link--active")
      document.getElementById("about").classList.remove("Nav__Link--active")
      document.getElementById("contact").classList.add("Nav__Link--active")

      // window.open("https://forms.gle/NuECpnMBH74oLMgT6", "_blank")
    }

  }, [page]);

  return (
    <>
      <div className="outer-container">
      <div className="logo-container">
        <div className="logo logo-on">
          <Svg name="logo-on"/>
        </div>
        <div className="logo logo-off">
          <Svg name="logo-off"/>
        </div>
      </div>
      <div className="content-container">
        <PageContent page={page}/>
        <div className="sine-wave">
          <div class="bars" id="bars">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>
        </div>
        <Nav>
          <Nav.Item onClick={() => setPage(0)}  id="services" label="Services" to="#Services"/>
          <Nav.Item onClick={() => setPage(1)}  id="about" label="About" to="#About"/>
          <Nav.Item onClick={() => setPage(2)} label="Portfolio" id="portfolio" to="#Portfolio"/>
          <Nav.Item onClick={() => setPage(3)} id="contact" label="Contact" to="#Contact"/>
          
          
        </Nav>
      </div>
      </div>
    </>
  );
}
