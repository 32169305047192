import React from 'react';

export default function Svg(props){

  let name = props.name

  // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 752 363.02" {...props}>
  //  <rect width={752} height={363.02} rx={22} fill="#1a1e26" />
 

    switch (name) {
      case "logo-on": return(
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 752 363.02">
          <rect width="752" height="363.02" fill="#1a1e26" rx="22"></rect>
          <path
            fill="#d5b229"
            d="M44.32 42.354h663.359a1.967 1.967 0 011.967 1.967v210.623a1.967 1.967 0 01-1.967 1.967H44.32a1.967 1.967 0 01-1.967-1.967V44.321a1.967 1.967 0 011.967-1.967z"
          ></path>
          <g style={{ isolation: "isolate" }} fill="#1a1818">
            <path d="M68.844 83.91l-9.778-12.762 1.32-.488 8.81 11.664.083-.03-.864-14.599 1.26-.465.861 16.055zM73.594 65.783l3.209 8.69c1.242 3.365 2.179 4.755 4.965 3.726s2.593-2.694 1.351-6.059l-3.21-8.69 1.239-.457 3.118 8.442c1.616 4.375 1.531 6.494-2.102 7.836s-5.075-.213-6.691-4.59l-3.118-8.441z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#b22125">
            <path d="M650.01 74.074l.498-16.07 1.331.459-.553 14.607.083.028 8.565-11.853 1.27.436-9.489 12.979zM665.154 63.039l-3.01 8.76c-1.166 3.392-1.321 5.061 1.487 6.027 2.81.965 3.714-.445 4.88-3.838l3.01-8.76 1.25.429-2.926 8.51c-1.516 4.412-2.923 6-6.585 4.74-3.662-1.258-3.796-3.375-2.28-7.786l2.926-8.51z"></path>
          </g>
          <path
            fill="#231f20"
            d="M480.813 105.922l-2.915-.707-5.164 21.305q-11.841-2.692-24.15-4.753l2.435-19.27-2.976-.376-2.422 19.166q-23.058-3.692-47.57-5.151l.414-18.202-2.999-.069-.411 18.091q-14.302-.767-29.02-.77-10.062 0-20.035.37l-.192-17.674-3 .032.193 17.758a540.43 540.43 0 00-47.892 4.143l-2.5-17.534-2.97.423 2.496 17.508c-14.89 2.051-29.345 4.702-43.184 7.896l-3.85-17.521-2.93.644 3.86 17.564a395.554 395.554 0 00-42.007 12.383l-7.091-17.694-2.785 1.115 7.056 17.606a276.953 276.953 0 00-36.208 16.202l-8.685-15.023-2.597 1.501 8.655 14.97c-13.461 7.553-24.924 15.883-33.9 24.853L116.03 172.27l-2.12 2.12 14.58 14.58 1.06-1.089c8.954-9.203 20.566-17.737 34.306-25.453l.012.022 1.17-.676c48.534-26.851 123.013-43.589 200.997-43.589 37.535 0 73.17 3.792 105.991 11.25l-.932 3.843 2.916.707.942-3.887 1.432.336.686-2.92-1.411-.332z"
          ></path>
          <circle cx="144.341" cy="196.032" r="5.711" fill="#1a1b1a"></circle>
          <circle cx="170.128" cy="175.023" r="5.711" fill="#1a1b1a"></circle>
          <circle cx="208.242" cy="158.628" r="5.711" fill="#1a1b1a"></circle>
          <circle cx="248.317" cy="145.433" r="5.711" fill="#1a1b1a"></circle>
          <circle cx="295.649" cy="137.806" r="5.711" fill="#1a1b1a"></circle>
          <circle cx="344.512" cy="132.095" r="5.711" fill="#1a1b1a"></circle>
          <circle cx="441.465" cy="140.732" r="5.711" fill="#1a1b1a"></circle>
          <circle cx="393.369" cy="135.02" r="5.711" fill="#1a1b1a"></circle>
          <circle cx="466.573" cy="146.442" r="5.711" fill="#1a1b1a"></circle>
          <g style={{ isolation: "isolate" }} fill="#1a1818">
            <path d="M67.796 153.171v-2.351h8.12v2.351zM95.292 160.507H82.244V158.1l6.524-5.88c1.904-1.708 3.276-3.528 3.276-5.516v-.336c0-2.352-1.26-3.696-3.668-3.696-2.38 0-3.584 1.317-4.2 3.332l-2.1-.784a6.227 6.227 0 016.356-4.592c3.892 0 6.076 2.352 6.076 5.656 0 2.968-1.764 5.152-4.368 7.42l-5.46 4.76h10.612zM98.624 150.736c0-6.385 2.072-10.109 6.832-10.109s6.832 3.724 6.832 10.109c0 6.383-2.072 10.107-6.832 10.107s-6.832-3.724-6.832-10.107zm11.228 1.595v-3.192c0-3.92-1.26-6.468-4.396-6.468s-4.396 2.548-4.396 6.468v3.192c0 3.92 1.26 6.468 4.396 6.468s4.396-2.548 4.396-6.468zm-6.132-1.288v-.616c0-.756.448-1.344 1.736-1.344s1.735.588 1.735 1.344v.616c0 .756-.447 1.344-1.735 1.344s-1.736-.588-1.736-1.344z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#1a1818">
            <path d="M124.711 123.746v-2.351h8.12v2.351zM139.55 131.082v-2.044h5.601v-15.763h-.196l-4.928 4.591-1.372-1.484 5.18-4.844h3.668v17.5h5.152v2.044zM155.539 121.31c0-6.384 2.072-10.108 6.832-10.108s6.832 3.724 6.832 10.109c0 6.384-2.072 10.107-6.832 10.107s-6.832-3.723-6.832-10.107zm11.228 1.596v-3.192c0-3.92-1.26-6.468-4.396-6.468s-4.396 2.548-4.396 6.468v3.192c0 3.92 1.26 6.468 4.396 6.468s4.396-2.548 4.396-6.468zm-6.132-1.288v-.616c0-.756.448-1.344 1.736-1.344s1.736.588 1.736 1.344v.616c0 .756-.448 1.344-1.736 1.344s-1.736-.588-1.736-1.344z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#1a1818">
            <path d="M220.99 98.554v-2.351h8.12v2.351zM238.293 105.89l7.644-17.528h-8.568v3.64h-2.1v-5.656h13.048v2.073l-7.504 17.471z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#1a1818">
            <path d="M273.99 88.866v-2.351h8.12v2.351zM300.477 78.702h-8.736l-.56 7.476h.196a4.506 4.506 0 014.088-2.128 5.614 5.614 0 015.908 6.02c0 3.808-2.492 6.468-6.664 6.468a7.096 7.096 0 01-6.216-3.332l1.764-1.343c1.036 1.54 2.1 2.631 4.508 2.631a3.787 3.787 0 004.172-4.116v-.223c0-2.577-1.512-4.117-4.228-4.117a4.318 4.318 0 00-3.584 1.653l-1.988-.28.7-10.753h10.64z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#1a1818">
            <path d="M325.442 82.92v-2.352h8.12v2.351zM345.462 79c2.715 0 4.087-1.373 4.087-3.249v-.196c0-2.071-1.456-3.136-3.584-3.136a4.368 4.368 0 00-4.116 2.492l-1.763-1.343a6.627 6.627 0 015.936-3.193c3.332 0 5.991 1.764 5.991 4.928a4.594 4.594 0 01-3.808 4.564v.112a4.87 4.87 0 014.2 4.928c0 3.36-2.688 5.684-6.748 5.684a7.276 7.276 0 01-6.412-3.332l1.764-1.344a5.019 5.019 0 004.648 2.632c2.744 0 4.285-1.344 4.285-3.668v-.224c0-2.295-1.457-3.64-4.312-3.64h-2.325v-2.016z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#1a1818">
            <path d="M375.692 82.92v-2.352h8.12v2.351zM403.188 90.255H390.14v-2.408l6.524-5.88c1.904-1.708 3.276-3.528 3.276-5.516v-.336c0-2.352-1.26-3.696-3.668-3.696-2.38 0-3.584 1.317-4.2 3.332l-2.1-.784a6.227 6.227 0 016.356-4.592c3.892 0 6.076 2.352 6.076 5.656 0 2.968-1.764 5.152-4.368 7.42l-5.46 4.76h10.612z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#1a1818">
            <path d="M432.918 88.866v-2.351h8.12v2.351zM447.758 96.202v-2.044h5.6V78.395h-.196l-4.928 4.592-1.372-1.485 5.18-4.844h3.668v17.5h5.152v2.044z"></path>
          </g>
          <path
            fill="#1a1818"
            d="M477.466 87.117c0-6.154 1.997-9.744 6.586-9.744s6.586 3.59 6.586 9.744-1.997 9.745-6.586 9.745-6.586-3.59-6.586-9.745zm10.824 1.539v-3.077c0-3.78-1.215-6.236-4.238-6.236-3.023 0-4.238 2.457-4.238 6.236v3.077c0 3.78 1.215 6.235 4.238 6.235 3.023 0 4.238-2.456 4.238-6.235zm-5.911-1.242v-.594c0-.728.431-1.295 1.673-1.295s1.674.567 1.674 1.295v.594c0 .73-.432 1.296-1.674 1.296s-1.673-.567-1.673-1.296z"
          ></path>
          <g style={{ isolation: "isolate" }} fill="#1a1818">
            <path d="M145.27 222.839h-8.625v-1.16l4.693-4.445c1.291-1.216 2.146-2.45 2.146-3.837v-.361a2.493 2.493 0 00-2.754-2.697 3.028 3.028 0 00-3.135 2.412l-1.007-.36a4.038 4.038 0 014.16-3.04 3.566 3.566 0 013.933 3.78c0 1.88-1.026 3.325-2.66 4.826l-4.18 3.893h7.429zM147.76 216.209c0-4.54 1.463-6.859 4.521-6.859s4.522 2.319 4.522 6.859-1.463 6.857-4.522 6.857-4.521-2.317-4.521-6.857zm7.827.892v-1.785c0-3.154-1.026-4.977-3.306-4.977s-3.305 1.823-3.305 4.977v1.785c0 3.154 1.026 4.978 3.305 4.978s3.306-1.824 3.306-4.978zm-4.218-.74v-.305c0-.398.247-.721.912-.721s.912.323.912.721v.304c0 .4-.247.723-.912.723s-.912-.323-.912-.723z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#1a1818">
            <path d="M206.742 188.51v-2.773h-6.687v-1.007l6.174-9.48h1.596v9.556h2.071v.93h-2.071v2.774zm-5.661-3.704h5.66v-8.587h-.056zM211.892 181.88c0-4.54 1.463-6.858 4.522-6.858s4.521 2.318 4.521 6.858-1.463 6.858-4.521 6.858-4.522-2.318-4.522-6.858zm7.827.893v-1.785c0-3.155-1.026-4.978-3.305-4.978-2.28 0-3.306 1.823-3.306 4.978v1.785c0 3.153 1.025 4.977 3.306 4.977 2.279 0 3.305-1.824 3.305-4.977zm-4.217-.742v-.303c0-.399.247-.722.912-.722s.91.323.91.722v.303c0 .4-.245.723-.91.723s-.912-.323-.912-.723z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#1a1818">
            <path d="M287.57 162.642a10.294 10.294 0 014.96-8.624h1.48c-2.963 2.014-4.596 3.951-5.242 7.066l.076.02a3.583 3.583 0 013.343-1.938 3.775 3.775 0 013.99 4.046 4.033 4.033 0 01-4.275 4.293c-2.83 0-4.331-1.956-4.331-4.863zm7.448.855v-.342a3.146 3.146 0 00-6.288 0v.342a3.146 3.146 0 006.288 0zM298.762 160.648c0-4.54 1.463-6.859 4.521-6.859s4.522 2.319 4.522 6.859-1.463 6.857-4.522 6.857-4.521-2.317-4.521-6.857zm7.827.892v-1.785c0-3.154-1.026-4.977-3.306-4.977s-3.305 1.823-3.305 4.977v1.785c0 3.154 1.026 4.978 3.305 4.978s3.306-1.824 3.306-4.978zm-4.218-.74v-.305c0-.398.247-.721.912-.721s.912.323.912.721v.304c0 .4-.247.723-.912.723s-.912-.324-.912-.723z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#1a1818">
            <path d="M381.903 157.766a3.252 3.252 0 012.774-3.362v-.134a3.086 3.086 0 01-2.356-3.134c0-2.014 1.54-3.325 4.104-3.325s4.103 1.311 4.103 3.325a3.085 3.085 0 01-2.356 3.134v.134a3.252 3.252 0 012.774 3.362c0 2.184-1.614 3.76-4.521 3.76s-4.522-1.576-4.522-3.76zm7.865.133v-.438c0-1.633-1.272-2.659-3.343-2.659s-3.344 1.026-3.344 2.66v.437c0 1.614 1.292 2.659 3.344 2.659s3.343-1.045 3.343-2.66zm-.399-6.403v-.341c0-1.463-1.12-2.375-2.944-2.375s-2.945.912-2.945 2.375v.341c0 1.463 1.102 2.375 2.945 2.375s2.944-.912 2.944-2.375zM393.303 154.67c0-4.54 1.463-6.86 4.522-6.86s4.521 2.32 4.521 6.86-1.463 6.857-4.521 6.857-4.522-2.318-4.522-6.858zm7.827.892v-1.785c0-3.155-1.025-4.978-3.305-4.978s-3.306 1.823-3.306 4.978v1.785c0 3.153 1.026 4.977 3.306 4.977s3.305-1.824 3.305-4.977zm-4.217-.741v-.304c0-.399.247-.722.912-.722s.911.323.911.722v.304c0 .399-.246.722-.911.722s-.912-.323-.912-.722z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#1a1818">
            <path d="M448.886 175.248v-.988h3.951v-11.436h-.095l-3.647 3.248-.646-.722 3.78-3.362h1.73v12.272h3.703v.988zM459.754 168.618c0-4.54 1.462-6.858 4.521-6.858s4.521 2.318 4.521 6.858-1.462 6.857-4.521 6.857-4.521-2.317-4.521-6.857zm7.827.892v-1.785c0-3.154-1.026-4.977-3.306-4.977s-3.306 1.823-3.306 4.977v1.785c0 3.154 1.026 4.978 3.306 4.978s3.306-1.824 3.306-4.978zm-4.218-.74v-.304c0-.399.247-.722.912-.722s.912.323.912.722v.303c0 .4-.247.723-.912.723s-.912-.323-.912-.723zM471.153 168.618c0-4.54 1.463-6.858 4.522-6.858s4.521 2.318 4.521 6.858-1.462 6.857-4.521 6.857-4.522-2.317-4.522-6.857zm7.828.892v-1.785c0-3.154-1.026-4.977-3.306-4.977s-3.306 1.823-3.306 4.977v1.785c0 3.154 1.026 4.978 3.306 4.978s3.306-1.824 3.306-4.978zm-4.218-.74v-.304c0-.399.247-.722.912-.722s.912.323.912.722v.303c0 .4-.247.723-.912.723s-.912-.323-.912-.723z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#b22125">
            <path d="M509.129 104.154v-5.852h-5.6v-1.904h5.6v-5.852h2.127v5.852h5.6v1.904h-5.6v5.852zM520.972 105.89v-2.044h5.6V88.082h-.195l-4.929 4.592-1.372-1.484 5.18-4.844h3.668v17.5h5.152v2.044z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#b22125">
            <path d="M554.005 119.75v-5.85h-5.6v-1.905h5.6v-5.852h2.128v5.852h5.6v1.904h-5.6v5.852zM578.505 121.487h-13.048v-2.408l6.524-5.88c1.904-1.708 3.276-3.528 3.276-5.516v-.336c0-2.352-1.26-3.696-3.668-3.696-2.38 0-3.584 1.317-4.2 3.332l-2.1-.784a6.227 6.227 0 016.356-4.592c3.892 0 6.076 2.352 6.076 5.656 0 2.968-1.764 5.152-4.368 7.42l-5.46 4.76h10.612z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#b22125">
            <path d="M605.033 158.77v-5.85h-5.6v-1.905h5.6v-5.852h2.128v5.852h5.6v1.904h-5.6v5.852zM622.057 149.251c2.716 0 4.088-1.372 4.088-3.248v-.196c0-2.071-1.456-3.136-3.584-3.136a4.368 4.368 0 00-4.116 2.492l-1.764-1.343a6.627 6.627 0 015.936-3.193c3.332 0 5.992 1.764 5.992 4.928a4.594 4.594 0 01-3.808 4.564v.112a4.87 4.87 0 014.2 4.928c0 3.36-2.688 5.684-6.748 5.684a7.276 7.276 0 01-6.412-3.332l1.764-1.344a5.019 5.019 0 004.648 2.632c2.744 0 4.284-1.344 4.284-3.668v-.223c0-2.296-1.456-3.641-4.312-3.641h-2.324v-2.016z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#fff">
            <path d="M278.45 282.043h5.542c2.897 0 4.599 1.633 4.599 4.208a3.272 3.272 0 01-2.552 3.403v.115a3.604 3.604 0 013.104 3.794 4.333 4.333 0 01-4.53 4.53h-6.163zm5.013 6.898c1.61 0 2.483-.598 2.483-2.046v-.828c0-1.448-.873-2.047-2.483-2.047h-2.483v4.921zm.368 7.175c1.702 0 2.668-.69 2.668-2.23v-.829c0-1.563-.966-2.23-2.668-2.23h-2.851v5.289zM297.792 298.093h-2.552v-16.05h6.001c3.036 0 4.715 1.84 4.715 4.829 0 2.437-1.243 4.14-3.473 4.507l3.702 6.714h-2.85l-3.404-6.484h-2.139zm3.082-8.553c1.609 0 2.391-.713 2.391-2.185v-.989c0-1.472-.782-2.185-2.391-2.185h-3.082v5.359zM311.915 298.093v-1.954h3.771v-12.141h-3.771v-1.955h10.118v1.955h-3.772v12.141h3.772v1.954zM337.465 298.093l-1.218-4.277h-5.105l-1.219 4.277h-2.621l4.69-16.05h3.519l4.69 16.05zm-3.679-13.452h-.183l-1.978 7.036h4.139zM347.725 285.745h-.207v12.348h-2.253v-16.05h3.31l4.715 12.348h.207v-12.348h2.254v16.05h-3.312zM387.03 295.886h-.16a3.608 3.608 0 01-3.611 2.484c-3.335 0-5.036-2.99-5.036-8.187 0-5.45 1.908-8.416 5.886-8.416a5.09 5.09 0 015.267 3.978l-2.323.782c-.483-1.402-1.127-2.621-2.92-2.621-2.231 0-3.173 1.862-3.173 4.806v2.552c0 2.944.827 4.99 3.172 4.99a2.74 2.74 0 002.898-2.99v-1.448h-3.104v-1.931h5.45v8.209h-2.346zM396.393 298.093v-16.05h2.575v13.89h7.312v2.16zM414.838 282.043v9.865c0 2.644.138 4.323 2.736 4.323 2.599 0 2.737-1.679 2.737-4.323v-9.865h2.575v9.405c0 4.599-.828 6.922-5.312 6.922s-5.312-2.323-5.312-6.922v-9.405zM429.42 298.093v-16.05h10.325v2.162h-7.749v4.668h7.036v2.16h-7.036v7.06z"></path>
          </g>
          <g style={{ isolation: "isolate" }} fill="#55aadf">
            <path d="M295.05 334.093l-2.12-7.438h-8.878l-2.12 7.438h-4.559l8.159-27.915h6.119l8.159 27.915zm-6.399-23.396h-.32l-3.439 12.238h7.199zM318.972 306.178v17.158c0 4.598.24 7.518 4.76 7.518 4.518 0 4.758-2.92 4.758-7.518v-17.158h4.48v16.358c0 7.998-1.44 12.037-9.239 12.037s-9.238-4.04-9.238-12.037v-16.358zM350.171 306.178h8.32c7.078 0 10.517 4.88 10.517 13.957 0 9.08-3.439 13.958-10.518 13.958h-8.319zm8.04 24.236c4.079 0 6.078-2.88 6.078-7.958v-4.64c0-5.079-2-7.959-6.079-7.959h-3.599v20.557zM385.252 334.093v-3.399h6.558v-21.116h-6.558v-3.4h17.596v3.4h-6.558v21.116h6.558v3.4zM418.971 320.135c0-9.118 3.16-14.437 10.239-14.437 7.078 0 10.238 5.32 10.238 14.437 0 9.12-3.16 14.438-10.238 14.438-7.08 0-10.239-5.318-10.239-14.438zm15.718 2.32v-4.679c0-5.119-1.48-8.358-5.48-8.358-3.999 0-5.478 3.239-5.478 8.358v4.72c0 5.119 1.48 8.358 5.479 8.358s5.479-3.239 5.479-8.398z"></path>
          </g>
          <circle cx="726.779" cy="340.284" r="5.711" fill="#414042"></circle>
          <circle cx="726.779" cy="21.414" r="5.711" fill="#414042"></circle>
          <circle cx="20.493" cy="21.414" r="5.711" fill="#414042"></circle>
          <circle cx="20.493" cy="340.284" r="5.711" fill="#414042"></circle>
          <path
            fill="#414042"
            d="M98.539 208.633L97.659 209.535 148.37 259.031 147.854 259.56 149.354 261.025 150.751 259.593 98.539 208.633z"
          ></path>
          <path fill="#1a1e26" d="M109.396 256.911H183.84V276.309H109.396z"></path>
          <path
            fill="#b22125"
            d="M611.14 168.462l-14.66 14.45c-11.363-11.657-26.063-22.305-43.407-31.61l9.24-18.456-2.683-1.342-9.208 18.393a286.297 286.297 0 00-38.174-16.297l5.743-18.343-2.863-.896-5.718 18.263c-10.46-3.528-21.448-6.658-32.884-9.346l-2.517 10.708c49.739 11.69 90.448 31.807 114.639 56.648l-.004.003 2.107 2.137 22.495-22.175z"
          ></path>
          <path
            fill="#231f20"
            d="M120.566 197.787H122.566V270.746H120.566z"
            transform="rotate(-45.695 121.566 234.267)"
          ></path>
        </svg>
      ) 
      
        // break;

        case "logo-off": return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 752 363.02">
            <rect width="752" height="363.02" fill="#1a1e26" rx="22"></rect>
            <path
              fill="#423815"
              d="M44.32 42.354h663.359a1.967 1.967 0 011.967 1.967v210.623a1.967 1.967 0 01-1.967 1.967H44.32a1.967 1.967 0 01-1.967-1.967V44.321a1.967 1.967 0 011.967-1.967z"
            ></path>
            <g style={{ isolation: "isolate" }} fill="#1a1818">
              <path d="M68.844 83.909l-9.778-12.76 1.32-.49 8.81 11.665.083-.03-.864-14.599 1.26-.465.861 16.054zM73.594 65.783l3.209 8.69c1.242 3.364 2.179 4.754 4.965 3.725 2.786-1.028 2.593-2.693 1.351-6.058l-3.21-8.69 1.239-.457 3.118 8.441c1.616 4.376 1.531 6.495-2.102 7.837s-5.075-.214-6.691-4.59l-3.118-8.441z"></path>
            </g>
            <g fill="#5d1816">
              <path d="M650.01 74.074l.498-16.07 1.331.459-.553 14.607.083.027 8.565-11.853 1.27.437-9.489 12.979zM665.155 63.039l-3.012 8.76c-1.166 3.392-1.32 5.06 1.488 6.027 2.81.965 3.713-.445 4.88-3.838l3.011-8.76 1.248.428-2.926 8.512c-1.515 4.41-2.922 5.998-6.584 4.74-3.662-1.26-3.796-3.377-2.279-7.787l2.925-8.512z"></path>
            </g>
            <path
              fill="#231f20"
              d="M480.813 105.922l-2.915-.707-5.164 21.305q-11.841-2.692-24.15-4.753l2.435-19.27-2.976-.376-2.422 19.166q-23.058-3.692-47.57-5.151l.414-18.202-2.999-.069-.411 18.091q-14.302-.767-29.02-.77-10.062 0-20.035.37l-.192-17.674-3 .032.193 17.758a540.43 540.43 0 00-47.892 4.143l-2.5-17.534-2.97.423 2.496 17.508c-14.89 2.051-29.345 4.702-43.184 7.896l-3.85-17.521-2.93.644 3.86 17.564a395.554 395.554 0 00-42.007 12.383l-7.091-17.694-2.785 1.115 7.056 17.606a276.953 276.953 0 00-36.208 16.202l-8.685-15.023-2.597 1.501 8.655 14.97c-13.461 7.553-24.924 15.883-33.9 24.853L116.03 172.27l-2.12 2.12 14.58 14.58 1.06-1.089c8.954-9.203 20.566-17.737 34.306-25.453l.012.022 1.17-.676c48.534-26.851 123.013-43.589 200.997-43.589 37.535 0 73.17 3.792 105.991 11.25l-.932 3.843 2.916.707.942-3.887 1.432.336.686-2.92-1.411-.332z"
            ></path>
            <circle cx="144.341" cy="196.032" r="5.711" fill="#1a1b1a"></circle>
            <circle cx="170.128" cy="175.023" r="5.711" fill="#1a1b1a"></circle>
            <circle cx="208.242" cy="158.628" r="5.711" fill="#1a1b1a"></circle>
            <circle cx="248.317" cy="145.433" r="5.711" fill="#1a1b1a"></circle>
            <circle cx="295.649" cy="137.806" r="5.711" fill="#1a1b1a"></circle>
            <circle cx="344.512" cy="132.095" r="5.711" fill="#1a1b1a"></circle>
            <circle cx="441.465" cy="140.732" r="5.711" fill="#1a1b1a"></circle>
            <circle cx="393.369" cy="135.02" r="5.711" fill="#1a1b1a"></circle>
            <circle cx="466.573" cy="146.442" r="5.711" fill="#1a1b1a"></circle>
            <g style={{ isolation: "isolate" }} fill="#1a1818">
              <path d="M67.796 153.171v-2.351h8.12v2.351zM95.292 160.507H82.244V158.1l6.524-5.88c1.904-1.708 3.276-3.528 3.276-5.516v-.336c0-2.352-1.26-3.696-3.668-3.696-2.38 0-3.584 1.317-4.2 3.332l-2.1-.784a6.227 6.227 0 016.356-4.592c3.892 0 6.076 2.352 6.076 5.656 0 2.968-1.764 5.152-4.368 7.42l-5.46 4.76h10.612zM98.624 150.736c0-6.385 2.072-10.109 6.832-10.109s6.832 3.724 6.832 10.109c0 6.383-2.072 10.107-6.832 10.107s-6.832-3.724-6.832-10.107zm11.228 1.595v-3.192c0-3.92-1.26-6.468-4.396-6.468s-4.396 2.548-4.396 6.468v3.192c0 3.92 1.26 6.468 4.396 6.468s4.396-2.548 4.396-6.468zm-6.132-1.288v-.616c0-.756.448-1.344 1.736-1.344s1.735.588 1.735 1.344v.616c0 .756-.447 1.344-1.735 1.344s-1.736-.588-1.736-1.344z"></path>
            </g>
            <g style={{ isolation: "isolate" }} fill="#1a1818">
              <path d="M124.711 123.746v-2.351h8.12v2.351zM139.55 131.082v-2.044h5.601v-15.763h-.196l-4.928 4.591-1.372-1.484 5.18-4.844h3.668v17.5h5.152v2.044zM155.539 121.31c0-6.384 2.072-10.108 6.832-10.108s6.832 3.724 6.832 10.109c0 6.384-2.072 10.107-6.832 10.107s-6.832-3.723-6.832-10.107zm11.228 1.596v-3.192c0-3.92-1.26-6.468-4.396-6.468s-4.396 2.548-4.396 6.468v3.192c0 3.92 1.26 6.468 4.396 6.468s4.396-2.548 4.396-6.468zm-6.132-1.288v-.616c0-.756.448-1.344 1.736-1.344s1.736.588 1.736 1.344v.616c0 .756-.448 1.344-1.736 1.344s-1.736-.588-1.736-1.344z"></path>
            </g>
            <g style={{ isolation: "isolate" }} fill="#1a1818">
              <path d="M220.99 98.554v-2.351h8.12v2.351zM238.293 105.89l7.644-17.528h-8.568v3.64h-2.1v-5.656h13.048v2.073l-7.504 17.471z"></path>
            </g>
            <g style={{ isolation: "isolate" }} fill="#1a1818">
              <path d="M273.99 88.866v-2.351h8.12v2.351zM300.477 78.702h-8.736l-.56 7.476h.196a4.506 4.506 0 014.088-2.128 5.614 5.614 0 015.908 6.02c0 3.808-2.492 6.468-6.664 6.468a7.096 7.096 0 01-6.216-3.332l1.764-1.343c1.036 1.54 2.1 2.631 4.508 2.631a3.787 3.787 0 004.172-4.116v-.223c0-2.577-1.512-4.117-4.228-4.117a4.318 4.318 0 00-3.584 1.653l-1.988-.28.7-10.753h10.64z"></path>
            </g>
            <g style={{ isolation: "isolate" }} fill="#1a1818">
              <path d="M325.442 82.92v-2.352h8.12v2.351zM345.462 79c2.715 0 4.087-1.373 4.087-3.249v-.196c0-2.071-1.456-3.136-3.584-3.136a4.368 4.368 0 00-4.116 2.492l-1.763-1.343a6.627 6.627 0 015.936-3.193c3.332 0 5.991 1.764 5.991 4.928a4.594 4.594 0 01-3.808 4.564v.112a4.87 4.87 0 014.2 4.928c0 3.36-2.688 5.684-6.748 5.684a7.276 7.276 0 01-6.412-3.332l1.764-1.344a5.019 5.019 0 004.648 2.632c2.744 0 4.285-1.344 4.285-3.668v-.224c0-2.295-1.457-3.64-4.312-3.64h-2.325v-2.016z"></path>
            </g>
            <g style={{ isolation: "isolate" }} fill="#1a1818">
              <path d="M375.692 82.92v-2.352h8.12v2.351zM403.188 90.255H390.14v-2.408l6.524-5.88c1.904-1.708 3.276-3.528 3.276-5.516v-.336c0-2.352-1.26-3.696-3.668-3.696-2.38 0-3.584 1.317-4.2 3.332l-2.1-.784a6.227 6.227 0 016.356-4.592c3.892 0 6.076 2.352 6.076 5.656 0 2.968-1.764 5.152-4.368 7.42l-5.46 4.76h10.612z"></path>
            </g>
            <g style={{ isolation: "isolate" }} fill="#1a1818">
              <path d="M432.918 88.866v-2.351h8.12v2.351zM447.758 96.202v-2.044h5.6V78.395h-.196l-4.928 4.592-1.372-1.485 5.18-4.844h3.668v17.5h5.152v2.044z"></path>
            </g>
            <path
              fill="#1a1818"
              d="M477.466 87.117c0-6.154 1.997-9.744 6.586-9.744s6.586 3.59 6.586 9.744-1.997 9.745-6.586 9.745-6.586-3.59-6.586-9.745zm10.824 1.539v-3.077c0-3.78-1.215-6.236-4.238-6.236-3.023 0-4.238 2.457-4.238 6.236v3.077c0 3.78 1.215 6.235 4.238 6.235 3.023 0 4.238-2.456 4.238-6.235zm-5.911-1.242v-.594c0-.728.431-1.295 1.673-1.295s1.674.567 1.674 1.295v.594c0 .73-.432 1.296-1.674 1.296s-1.673-.567-1.673-1.296z"
            ></path>
            <g style={{ isolation: "isolate" }} fill="#1a1818">
              <path d="M145.27 222.839h-8.625v-1.16l4.693-4.445c1.291-1.216 2.146-2.45 2.146-3.837v-.361a2.493 2.493 0 00-2.754-2.697 3.028 3.028 0 00-3.135 2.412l-1.007-.36a4.038 4.038 0 014.16-3.04 3.566 3.566 0 013.933 3.78c0 1.88-1.026 3.325-2.66 4.826l-4.18 3.893h7.429zM147.76 216.209c0-4.54 1.463-6.859 4.521-6.859s4.522 2.319 4.522 6.859-1.463 6.857-4.522 6.857-4.521-2.317-4.521-6.857zm7.827.892v-1.785c0-3.154-1.026-4.977-3.306-4.977s-3.305 1.823-3.305 4.977v1.785c0 3.154 1.026 4.978 3.305 4.978s3.306-1.824 3.306-4.978zm-4.218-.74v-.305c0-.398.247-.721.912-.721s.912.323.912.721v.304c0 .4-.247.723-.912.723s-.912-.323-.912-.723z"></path>
            </g>
            <g style={{ isolation: "isolate" }} fill="#1a1818">
              <path d="M206.742 188.51v-2.773h-6.687v-1.007l6.174-9.48h1.596v9.556h2.071v.93h-2.071v2.774zm-5.661-3.704h5.66v-8.587h-.056zM211.892 181.88c0-4.54 1.463-6.858 4.522-6.858s4.521 2.318 4.521 6.858-1.463 6.858-4.521 6.858-4.522-2.318-4.522-6.858zm7.827.893v-1.785c0-3.155-1.026-4.978-3.305-4.978-2.28 0-3.306 1.823-3.306 4.978v1.785c0 3.153 1.025 4.977 3.306 4.977 2.279 0 3.305-1.824 3.305-4.977zm-4.217-.742v-.303c0-.399.247-.722.912-.722s.91.323.91.722v.303c0 .4-.245.723-.91.723s-.912-.323-.912-.723z"></path>
            </g>
            <g style={{ isolation: "isolate" }} fill="#1a1818">
              <path d="M287.57 162.642a10.294 10.294 0 014.96-8.624h1.48c-2.963 2.014-4.596 3.951-5.242 7.066l.076.02a3.583 3.583 0 013.343-1.938 3.775 3.775 0 013.99 4.046 4.033 4.033 0 01-4.275 4.293c-2.83 0-4.331-1.956-4.331-4.863zm7.448.855v-.342a3.146 3.146 0 00-6.288 0v.342a3.146 3.146 0 006.288 0zM298.762 160.648c0-4.54 1.463-6.859 4.521-6.859s4.522 2.319 4.522 6.859-1.463 6.857-4.522 6.857-4.521-2.317-4.521-6.857zm7.827.892v-1.785c0-3.154-1.026-4.977-3.306-4.977s-3.305 1.823-3.305 4.977v1.785c0 3.154 1.026 4.978 3.305 4.978s3.306-1.824 3.306-4.978zm-4.218-.74v-.305c0-.398.247-.721.912-.721s.912.323.912.721v.304c0 .4-.247.723-.912.723s-.912-.324-.912-.723z"></path>
            </g>
            <g style={{ isolation: "isolate" }} fill="#1a1818">
              <path d="M381.903 157.766a3.252 3.252 0 012.774-3.362v-.134a3.086 3.086 0 01-2.356-3.134c0-2.014 1.54-3.325 4.104-3.325s4.103 1.311 4.103 3.325a3.085 3.085 0 01-2.356 3.134v.134a3.252 3.252 0 012.774 3.362c0 2.184-1.614 3.76-4.521 3.76s-4.522-1.576-4.522-3.76zm7.865.133v-.438c0-1.633-1.272-2.659-3.343-2.659s-3.344 1.026-3.344 2.66v.437c0 1.614 1.292 2.659 3.344 2.659s3.343-1.045 3.343-2.66zm-.399-6.403v-.341c0-1.463-1.12-2.375-2.944-2.375s-2.945.912-2.945 2.375v.341c0 1.463 1.102 2.375 2.945 2.375s2.944-.912 2.944-2.375zM393.303 154.67c0-4.54 1.463-6.86 4.522-6.86s4.521 2.32 4.521 6.86-1.463 6.857-4.521 6.857-4.522-2.318-4.522-6.858zm7.827.892v-1.785c0-3.155-1.025-4.978-3.305-4.978s-3.306 1.823-3.306 4.978v1.785c0 3.153 1.026 4.977 3.306 4.977s3.305-1.824 3.305-4.977zm-4.217-.741v-.304c0-.399.247-.722.912-.722s.911.323.911.722v.304c0 .399-.246.722-.911.722s-.912-.323-.912-.722z"></path>
            </g>
            <g style={{ isolation: "isolate" }} fill="#1a1818">
              <path d="M448.886 175.248v-.988h3.951v-11.436h-.095l-3.647 3.248-.646-.722 3.78-3.362h1.73v12.272h3.703v.988zM459.754 168.618c0-4.54 1.462-6.858 4.521-6.858s4.521 2.318 4.521 6.858-1.462 6.857-4.521 6.857-4.521-2.317-4.521-6.857zm7.827.892v-1.785c0-3.154-1.026-4.977-3.306-4.977s-3.306 1.823-3.306 4.977v1.785c0 3.154 1.026 4.978 3.306 4.978s3.306-1.824 3.306-4.978zm-4.218-.74v-.304c0-.399.247-.722.912-.722s.912.323.912.722v.303c0 .4-.247.723-.912.723s-.912-.323-.912-.723zM471.153 168.618c0-4.54 1.463-6.858 4.522-6.858s4.521 2.318 4.521 6.858-1.462 6.857-4.521 6.857-4.522-2.317-4.522-6.857zm7.828.892v-1.785c0-3.154-1.026-4.977-3.306-4.977s-3.306 1.823-3.306 4.977v1.785c0 3.154 1.026 4.978 3.306 4.978s3.306-1.824 3.306-4.978zm-4.218-.74v-.304c0-.399.247-.722.912-.722s.912.323.912.722v.303c0 .4-.247.723-.912.723s-.912-.323-.912-.723z"></path>
            </g>
            <g style={{ isolation: "isolate" }} fill="#5d1816">
              <path d="M509.129 104.154v-5.852h-5.6v-1.904h5.6v-5.852h2.127v5.852h5.6v1.904h-5.6v5.852zM520.972 105.89v-2.044h5.6V88.082h-.195l-4.929 4.592-1.372-1.484 5.18-4.844h3.668v17.5h5.152v2.044z"></path>
            </g>
            <g style={{ isolation: "isolate" }} fill="#5d1816">
              <path d="M554.005 119.75v-5.85h-5.6v-1.905h5.6v-5.852h2.128v5.852h5.6v1.904h-5.6v5.852zM578.505 121.487h-13.048v-2.408l6.524-5.88c1.904-1.708 3.276-3.528 3.276-5.516v-.336c0-2.352-1.26-3.696-3.668-3.696-2.38 0-3.584 1.317-4.2 3.332l-2.1-.784a6.227 6.227 0 016.356-4.592c3.892 0 6.076 2.352 6.076 5.656 0 2.968-1.764 5.152-4.368 7.42l-5.46 4.76h10.612z"></path>
            </g>
            <g style={{ isolation: "isolate" }} fill="#5d1816">
              <path d="M605.033 158.77v-5.85h-5.6v-1.905h5.6v-5.852h2.128v5.852h5.6v1.904h-5.6v5.852zM622.057 149.251c2.716 0 4.088-1.372 4.088-3.248v-.196c0-2.071-1.456-3.136-3.584-3.136a4.368 4.368 0 00-4.116 2.492l-1.764-1.343a6.627 6.627 0 015.936-3.193c3.332 0 5.992 1.764 5.992 4.928a4.594 4.594 0 01-3.808 4.564v.112a4.87 4.87 0 014.2 4.928c0 3.36-2.688 5.684-6.748 5.684a7.276 7.276 0 01-6.412-3.332l1.764-1.344a5.019 5.019 0 004.648 2.632c2.744 0 4.284-1.344 4.284-3.668v-.223c0-2.296-1.456-3.641-4.312-3.641h-2.324v-2.016z"></path>
            </g>
            <circle cx="726.779" cy="340.284" r="5.711" fill="#414042"></circle>
            <circle cx="726.779" cy="21.414" r="5.711" fill="#414042"></circle>
            <circle cx="20.493" cy="21.414" r="5.711" fill="#414042"></circle>
            <circle cx="20.493" cy="340.284" r="5.711" fill="#414042"></circle>
            <path
              fill="#414042"
              d="M98.539 208.633L97.659 209.535 148.37 259.031 147.854 259.56 149.354 261.025 150.751 259.593 98.539 208.633z"
            ></path>
            <path fill="#1a1e26" d="M109.396 256.911H183.84V276.309H109.396z"></path>
            <path
              fill="#5d1816"
              d="M611.14 168.462l-14.66 14.45c-11.363-11.657-26.063-22.305-43.407-31.61l9.24-18.456-2.683-1.342-9.208 18.393a286.297 286.297 0 00-38.174-16.297l5.743-18.343-2.863-.896-5.718 18.263c-10.46-3.528-21.448-6.658-32.884-9.346l-2.517 10.708c49.739 11.69 90.448 31.807 114.639 56.648l-.004.003 2.107 2.137 22.495-22.175z"
            ></path>
            <path
              fill="#231f20"
              d="M120.566 197.787H122.566V270.746H120.566z"
              transform="rotate(-45.695 121.566 234.267)"
            ></path>
            <path
              fill="#fff"
              d="M278.45 282.043h5.542c2.897 0 4.599 1.633 4.599 4.208a3.272 3.272 0 01-2.552 3.403v.115a3.604 3.604 0 013.104 3.794 4.333 4.333 0 01-4.53 4.53h-6.163zm5.013 6.898c1.61 0 2.483-.598 2.483-2.046v-.828c0-1.448-.873-2.047-2.483-2.047h-2.483v4.921zm.368 7.175c1.702 0 2.668-.69 2.668-2.23v-.829c0-1.563-.966-2.23-2.668-2.23h-2.851v5.289zM297.792 298.093h-2.552v-16.05h6.001c3.036 0 4.715 1.84 4.715 4.829 0 2.437-1.243 4.14-3.473 4.507l3.702 6.714h-2.85l-3.404-6.484h-2.139zm3.082-8.553c1.609 0 2.391-.713 2.391-2.185v-.989c0-1.472-.782-2.185-2.391-2.185h-3.082v5.359zM311.915 298.093v-1.954h3.771v-12.141h-3.771v-1.955h10.118v1.955h-3.772v12.141h3.772v1.954zM337.465 298.093l-1.218-4.277h-5.105l-1.219 4.277h-2.621l4.69-16.05h3.519l4.69 16.05zm-3.679-13.452h-.183l-1.978 7.036h4.139zM347.725 285.745h-.207v12.348h-2.253v-16.05h3.31l4.715 12.348h.207v-12.348h2.254v16.05h-3.312zM387.03 295.886h-.16a3.608 3.608 0 01-3.611 2.484c-3.335 0-5.036-2.99-5.036-8.187 0-5.45 1.908-8.416 5.886-8.416a5.09 5.09 0 015.267 3.978l-2.323.782c-.483-1.402-1.127-2.621-2.92-2.621-2.231 0-3.173 1.862-3.173 4.806v2.552c0 2.944.827 4.99 3.172 4.99a2.74 2.74 0 002.898-2.99v-1.448h-3.104v-1.931h5.45v8.209h-2.346zM396.393 298.093v-16.05h2.575v13.89h7.312v2.16zM414.838 282.043v9.865c0 2.644.138 4.323 2.736 4.323 2.599 0 2.737-1.679 2.737-4.323v-9.865h2.575v9.405c0 4.599-.828 6.922-5.312 6.922s-5.312-2.323-5.312-6.922v-9.405zM429.42 298.093v-16.05h10.326v2.162h-7.75v4.668h7.037v2.16h-7.036v7.06z"
            ></path>
            <path
              fill="#55aadf"
              d="M295.05 334.093l-2.12-7.438h-8.878l-2.12 7.438h-4.559l8.159-27.915h6.119l8.159 27.915zm-6.399-23.396h-.32l-3.439 12.238h7.199zM318.972 306.178v17.158c0 4.598.24 7.518 4.76 7.518 4.518 0 4.758-2.92 4.758-7.518v-17.158h4.48v16.358c0 7.998-1.44 12.037-9.239 12.037s-9.238-4.04-9.238-12.037v-16.358zM350.171 306.178h8.32c7.078 0 10.517 4.88 10.517 13.957 0 9.08-3.439 13.958-10.518 13.958h-8.319zm8.04 24.236c4.079 0 6.078-2.88 6.078-7.958v-4.64c0-5.079-2-7.959-6.079-7.959h-3.599v20.557zM385.252 334.093v-3.399h6.558v-21.116h-6.558v-3.4h17.596v3.4h-6.558v21.116h6.558v3.4zM418.971 320.135c0-9.118 3.16-14.437 10.239-14.437s10.238 5.32 10.238 14.437c0 9.12-3.16 14.438-10.238 14.438s-10.239-5.318-10.239-14.438zm15.718 2.32v-4.679c0-5.119-1.48-8.358-5.48-8.358s-5.478 3.239-5.478 8.358v4.72c0 5.119 1.48 8.358 5.479 8.358s5.48-3.239 5.48-8.398z"
            ></path>
          </svg>
        )
    
      default:
        break;
    }

}